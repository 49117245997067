<template>
  <v-container fluid fill-height>
    <v-layout column align-center justify-center>
      <v-toolbar dense small flat color="transparent">
        <v-spacer></v-spacer>
        <v-toolbar-items class="text-right">
          <!-- <v-btn color="primary" @click="register">Register</v-btn> -->
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <!-- loging -->

            <!-- <v-expansion-panel popout
        v-model="expansion">
          <v-expansion-panel-content>
            <template v-slot:header>
              <div>Login</div>
            </template>-->
            <v-card class="elevation-12 rounded mt-5">
              <v-toolbar color="primary" dark flat>
                <v-layout justify-start>
                  <v-toolbar-title>Login</v-toolbar-title>
                </v-layout>
                <!-- <v-progress-linear :indeterminate="true"></v-progress-linear> -->
                <v-img
                  justify-end
                  wrap
                  :src="require('@/assets/nu_logos/nu_logo_r.png')"
                  contain
                  height="100"
                ></v-img>
              </v-toolbar>

              <v-card-text class="pa-0 pt-3 pr-4 pl-3">
                <v-form>
                  <v-text-field
                    v-model="user.email"
                    label="Login"
                    name="login"
                    prepend-icon="person"
                    type="text"
                    @keyup.enter="checkUser()"
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                    @keyup.enter="checkUser()"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-progress-linear
                v-if="loader"
                :indeterminate="true"
              ></v-progress-linear>
              <v-card-actions>
                <v-layout justify-center class="pb-2">
                  <v-btn color="primary" @click="checkUser">
                    <v-icon left dark>account_circle</v-icon>Login
                  </v-btn>
                </v-layout>
              </v-card-actions>
              <v-flex mb-4></v-flex>
            </v-card>
            <v-flex class="col-12 mt-2">
              <table
                class="table table-md table-hover text-left"
                style="width: 100%"
              >
                <tbody>
                  <tr v-if="module == 'center-user'">
                    <td class="p-10">
                      Instruction for Practical & Viva mark entry
                    </td>
                    <td class="p-10">
                      <a
                        class="text-dec-none"
                        :href="
                          getBaseUrl +
                            '/' +
                            'Instruction for PV mark entry updated.pdf'
                        "
                        target="_blank"
                        >Download</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-flex>
            <!-- </v-expansion-panel-content>
            </v-expansion-panel>-->

            <!-- <v-layout justify-center>
          <v-btn color="primary" outline @click="goStart">Start</v-btn>
            </v-layout>-->
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import common_login_getters from "@/mixin/common_login_getters";
import commonGet from "@/mixin/common_getters_mixin";
export default {
  mixins: [commonGet, common_login_getters],
  data() {
    return {
      module: "",
      expansion: true,
      loader: false,
      user: {
        password: "",
        email: "",
      },
    };
  },
  computed: {
    getBaseUrl() {
      return this.$store.getters.getBaseUrl + "/user-manual";
    },
  },
  mounted() {
    const moduleName = this.$route.query.module;
    this.module = moduleName;
  },
  components: {},
  methods: {
    register() {
      this.$router.push("/register");
    },

    goStart() {
      this.$router.push("/");
    },
    goHome() {
      this.$router.push("/home");
    },
    goTo() {
      if (this.ifCenterUser) {
        this.$router.push("/center/center-dashboard");
      } else if (this.ifInvigilatorUser) {
        this.$router.push("/invigilator-dashboard");
      } else if (this.ifRegionalUser) {
        this.$router.push("/regional-center-dashboard");
      } else if (this.ifRescrutinerUser) {
        this.$router.push("/rescrutiny-std-list");
      } else if (this.ifExaminerUser) {
        this.$router.push("/examiner/examiner-dashboard");
      } else if (this.ifHeadExaminerUser) {
        this.$router.push("/examiner-by-head-examiner");
      } else if (this.ifDirectorICTUser) {
        this.$router.push("/result-approval");
      } else if (this.ifSeniorAnalystUser) {
        this.$router.push("/result-approval");
      } else if (this.ifITUser) {
        this.$router.push("/dashboard");
      } else if (this.ifControllerUser) {
        this.$router.push("/result-approval");
      } else if (this.ifVcUser) {
        this.$router.push("/result-approval");
      } else if (this.ifAdminUserType || this.ifControllerUser) {
        this.$router.push("/dashboard");
      } else {
        this.$router.push("/home");
      }
    },
    checkUser() {
      console.log("Tanvir this is user", this.user);
      this.loader = true;
      this.$store
        .dispatch("authenticateUser", this.user)
        .then(() => {
          this.loader = false;
          if (this.getAuthUser.user_type == 2) {
            this.setCorrectCenter();
          }
          //if (this.ifCenterUser ) {
          //this.setCorrectCenter();
          //}
          this.goTo();
        })
        .catch((ex) => {
          console.log("ex", ex);
          this.loader = false;
          alert("wrong... authentication");
        });
    },
    setCorrectCenter() {
      let payload = {};
      if (this.getAuthUser.type == 103) {
        payload.type = 2;
      } else {
        payload.type = 1;
      }
      payload.center_list_id = this.getAuthUser.center.id;
      payload.exam_initiate_id = this.getExamInitiateId;
      console.log("payload === ", payload);
      this.$store.dispatch("setCorrectCenter", payload);
    },
  },
};
</script>

<style scoped>
.rounded {
  border-radius: 15px;
}
.p-10 {
  padding: 10px !important;
}
.text-dec-none {
  text-decoration: none !important;
}
</style>
